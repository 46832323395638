<template>
  <div class="px-3 py-2">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <img src="@/assets/images/logo/logo.png" alt="logo" width="80" />
        <span class="title-logo"> Smartworks </span>
      </div>

      <b-link
        to="/performance"
        class="d-flex align-items-center bg-white p-1 rounded-pill cursor-pointer"
      >
        &#x2715;
        <span class="bold ml-1">Close</span>
      </b-link>
    </div>

    <div class="text-center mt-3">
      <h2 style="color: #1e1e1e">{{ head_title }}</h2>
      <span class="text-muted">
        {{ head_subtitle }}
      </span>
    </div>

    <b-row class="justify-content-center">
      <b-col md="8">
        <swiper
          class="swiper-progress mt-3"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide v-for="(data, index) in swiperData" :key="index">
            <b-card :title="`Goal ${(index += 1)}`">
              <b-card-body class="mx-2">
                <b-form>
                  <b-form-group label="Strategic Target">
                    <b-form-input v-model="data.strategic_goals" size="sm" />
                  </b-form-group>

                  <b-form-group label="Key Performance Indicator">
                    <b-form-textarea
                      v-model="data.key_performance_indicator"
                      size="sm"
                    />
                  </b-form-group>

                  <b-form-group label="Weight">
                    <b-form-input v-model="data.weight" size="sm" />
                  </b-form-group>

                  <b-form-group label="Target">
                    <b-form-input v-model="data.target" size="sm" />
                  </b-form-group>

                  <b-form-group label="Due Date">
                    <flat-pickr
                      v-model="data.due_date"
                      :config="{
                        dateFormat: 'Y-m-d',
                        altInput: true,
                        altFormat: 'F j, Y',
                        allowInput: true,
                      }"
                      class="form-control form-control-sm"
                    />
                  </b-form-group>

                  <b-form-group
                    label="Midyear Realization"
                    v-if="realization == true"
                  >
                    <b-form-input v-model="data.realization" size="sm" />
                  </b-form-group>

                  <b-form-group
                    label="Final Realization"
                    v-if="final_realization == true"
                  >
                    <b-form-input v-model="data.final_realization" size="sm" />
                  </b-form-group>

                  <b-form-group
                    label="Final Status"
                    v-if="final_status == true"
                  >
                    <b-form-select
                      id="final_status"
                      v-model="data.final_goal_status"
                      size="sm"
                      :options="[
                        {
                          text: 'Complete',
                          value: 'complete',
                        },
                        {
                          text: 'Incomplete',
                          value: 'incomplete',
                        },
                      ]"
                    />
                  </b-form-group>

                  <b-form-group label="Final Score" v-if="final_score == true">
                    <b-form-select
                      id="final_score"
                      v-model="data.final_score"
                      size="sm"
                      :options="[
                        {
                          text: 'Has not met Objectives',
                          value: '0',
                        },
                        {
                          text: 'Met Some Objectives but not All',
                          value: '1',
                        },
                        {
                          text: 'Met Objectives',
                          value: '2',
                        },
                        {
                          text: 'Exceed Some Objectives & Met Others',
                          value: '3',
                        },
                        {
                          text: 'Exceed All Objectives',
                          value: '4',
                        },
                      ]"
                    />
                  </b-form-group>

                  <b-form-group label="Note" v-if="note">
                    <b-form-textarea
                      v-model="data.note"
                      size="sm"
                      placeholder="Write a note"
                    />
                  </b-form-group>
                </b-form>
              </b-card-body>
              <b-card-footer
                class="d-flex justify-content-between"                
              >
                <div>
                  <b-button
                    variant="outline-primary"
                    class="rounded-3 mr-1"
                    @click="removeGoal"
                    size="sm"
                    v-if="                      
                      type != 'new_goals_meeting' &&
                      type != 'new_midyear' &&
                      type != 'new_final'
                    "
                  >
                    Remove
                  </b-button>
                </div>
                <div>
                  <b-button
                    variant="outline-primary"
                    class="rounded-3 mr-1"
                    @click="addGoal"
                    size="sm"
                    :disabled="index <= swiperData.length - 1"
                    v-if="
                      type != 'new_goals_meeting' &&
                      type != 'new_midyear' &&
                      type != 'new_final'
                    "
                  >
                    Add Form Goal
                  </b-button>
                  <!-- <b-button
                    variant="primary"
                    class="rounded-3"
                    @click="submitGoal"
                    size="sm"
                    v-if="type != 'new_final'"
                  >
                    {{
                      type != "goals_setting_send_back" &&
                      type != "new_goals_meeting"
                        ? "Submit"
                        : "Update"
                    }}
                  </b-button> -->
                  <b-button
                    variant="primary"
                    class="rounded-3"
                    size="sm"
                    @click="goSummary($event)"
                    :disabled="index <= swiperData.length - 1"
                  >
                    Continue
                  </b-button>
                </div>
              </b-card-footer>
            </b-card>
          </swiper-slide>

          <!-- Add Arrows -->
          <div slot="button-next" class="swiper-button-next" />
          <div slot="button-prev" class="swiper-button-prev" />
          <div slot="pagination" class="swiper-pagination" />
        </swiper>
      </b-col>
    </b-row>
  </div>
</template>

<style>
.title-logo {
  font-size: 1.5rem;
  font-weight: 600;
  color: #423ffd;
  margin-left: 0.5rem;
}

.rounded-3 {
  border-radius: 1.75rem;
}

.form-control:read-only {
  background-color: #fff;
}
</style>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      swiperData: [],
      /* eslint-disable global-require */

      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          type: "progressbar",
        },
      },

      goal_id: [],
      endpoint: "",
      type: "",
      head_title: "Create Goals",
      head_subtitle: "Create goals for KPIS",
      realization: false,
      final_realization: false,
      final_status: false,
      note: false,
      final_score: false,
    };
  },

  mounted() {
    if (this.$route.params.user_id != undefined) {
      localStorage.setItem("ruid", this.$route.params.user_id);
    }

    if (this.$route.params.type != undefined) {
      localStorage.setItem("rtype", this.$route.params.type);
    }
    this.type = localStorage.getItem("rtype");

    if (this.type == "new_goals") {
      this.goal_id.push(1);
      this.swiperData.push({
        user_id: JSON.parse(localStorage.getItem("sw_auth_data")).id,
        strategic_goals: "",
        key_performance_indicator: "",
        weight: "",
        target: "",
        due_date: "",
        status: "new",
      });
    } else if (this.type == "goals_setting_send_back") {
      this.head_title = "Update Goals";
      this.head_subtitle = "Update goals for KPIS";
      this.getGoals();
    } else if (this.type == "new_goals_meeting") {
      this.head_title = "Review Goals";
      this.head_subtitle = "Update goals if necessary";
      this.getGoals();
    } else if (this.type == "new_midyear") {
      this.head_title = "Update Midyear Goals";
      this.head_subtitle = "Update midyear goals for KPIS";
      this.getGoals();
    } else if (this.type == "new_final") {
      this.head_title = "Create Annual Goals";
      this.head_subtitle = "Create annual goals for KPIS";
      this.getGoals();
    } else {
      this.getGoals();
    }
  },

  methods: {
    getGoals() {
      this.type = localStorage.getItem("rtype");
      if (this.type == "new_midyear") {
        this.endpoint = "/goals/fetch";
      } else if (this.type == "new_final") {
        this.endpoint = "/goals/fetch";
      } else {
        this.endpoint = "/goals/fetch";
      }

      this.callApi({
        url: this.endpoint,
        method: "GET",
        params: {
          user_id: this.hashid(
            JSON.parse(localStorage.getItem("sw_auth_data")).id
          ),
          detail: 1,
        },
        success: (res) => {
          if (this.type == "new_midyear") {
            res.result.map((data) => {
              this.goal_id.push(data.id);
              this.realization = true;
              this.note = true;
              this.swiperData.push({
                id: data.id,
                strategic_goals: data.strategic_goals,
                key_performance_indicator: data.key_performance_indicator,
                weight: data.weight,
                target: data.target,
                due_date: data.due_date,
                realization: "",
                status: "new",
                note: "",
              });
            });
          } else if (this.type == "goals_setting_send_back") {
            res.result.map((data) => {
              this.goal_id.push(data.id);
              this.note = true;
              this.swiperData.push({
                id: data.id,
                strategic_goals: data.strategic_goals,
                key_performance_indicator: data.key_performance_indicator,
                weight: data.weight,
                target: data.target,
                due_date: data.due_date,
                status: "new",
                note: "",
              });
            });
          } else if (this.type == "new_final") {
            res.result.map((data) => {
              this.goal_id.push(data.id);
              this.realization = true;
              this.final_realization = true;
              this.final_status = true;
              this.final_score = true;
              this.note = true;
              this.swiperData.push({
                id: data.id,
                strategic_goals: data.strategic_goals,
                key_performance_indicator: data.key_performance_indicator,
                weight: data.weight,
                target: data.target,
                due_date: data.due_date,
                realization: data.midyear_evaluation.midyear_realization,
                status: "new",
                note: "",
              });
            });
          } else {
            res.result.map((data) => {
              this.goal_id.push(data.id);
              this.swiperData.push({
                id: data.id,
                strategic_goals: data.strategic_goals,
                key_performance_indicator: data.key_performance_indicator,
                weight: data.weight,
                target: data.target,
                due_date: data.due_date,
                note: "",
                status: "new",
              });
            });
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
    },

    addGoal() {
      if (this.swiperData.length < 10) {
        this.swiperData.push({
          user_id: JSON.parse(localStorage.getItem("sw_auth_data")).id,
          strategic_goals: "",
          key_performance_indicator: "",
          weight: "",
          target: "",
          due_date: "",
          status: "new",
        });
      }
    },

    removeGoal() {
      if (this.swiperData.length > 1) {
        this.swiperData.pop();
      }
    },

    submitGoal() {
      if (this.type == "new_goals") {
        this.createGoals();
      } else if (this.type == "goals_setting_send_back") {
        this.updateGoals();
      } else if (this.type == "new_midyear") {
        this.updateMidyearGoals();
      } else if (this.type == "new_final") {
        this.updateAnnualGoals();
      } else {
        this.updateGoals();
      }
    },

    createGoals() {
      this.callApi({
        url: "/goals/create",
        method: "POST",
        data: this.swiperData,
        success: (res) => {
          this.$router.push("/performance");
          localStorage.setItem("goals", []);
        },
        error: (err) => {
          console.log(err);
        },
      });
    },

    updateGoals() {
      this.callApi({
        url: `/goals/update/${this.hashid(localStorage.getItem("ruid"))}`,
        method: "POST",
        data: this.swiperData,
        success: (res) => {
          this.$router.push("/performance");
          localStorage.setItem("goals", []);
        },
        error: (err) => {
          console.log(err);
        },
      });
    },

    updateMidyearGoals() {
      let datas = [];
      this.swiperData.map((data, index) => {
        datas.push({
          user_id: JSON.parse(localStorage.getItem("sw_auth_data")).id,
          goal_id: data.id,
          midyear_realization: data.realization,
          status: data.status,
        });
      });

      this.callApi({
        url: `/midyear_evaluations/create`,
        method: "POST",
        data: datas,
        success: (res) => {
          this.$router.push("/performance");
          localStorage.setItem("goals", []);
        },
        error: (err) => {
          console.log(err);
        },
      });
    },

    sendBack() {
      this.callApi({
        url: "/goals/sendback",
        method: "POST",
        data: {
          user_id: this.hashid(localStorage.getItem("ruid")),
          goals: this.swiperData,
        },
        success: (res) => {
          this.$router.push("/performance");
          localStorage.setItem("goals", []);
        },
        error: (err) => {
          console.log(err);
        },
      });
    },

    approve() {
      this.swiperData.map((data, index) => {
        this.callApi({
          url: `/goals/update/${this.hashid(this.goal_id[index])}/${this.hashid(
            localStorage.getItem("ruid")
          )}`,
          method: "POST",
          data: {
            status: "approved_meeting",
          },
          success: (res) => {
            // this.$router.push("/performance");
            localStorage.setItem("goals", []);
          },
          error: (err) => {
            console.log(err);
          },
        });
      });
    },

    goSummary(e) {
      e.preventDefault();

      if (this.type != "new_goals") {
        this.callApi({
          url: "goals/fetch",
          method: "GET",
          params: {
            user_id: this.hashid(
              JSON.parse(localStorage.getItem("sw_auth_data")).id
            ),
            detail: 1,
          },
          success: (res) => {
            localStorage.setItem("goals", []);
            let goals = [];
            res.result.map((item) => {
              goals.push({
                item,
                other: this.swiperData,
              });
            });

            localStorage.setItem("goals", JSON.stringify(goals));

            if (this.type == "new_final") {
              this.$router.push("/performance/goal/final_behavior");
            } else {
              this.$router.push("/performance/goal/summary");
            }
          },
        });
      } else {
        localStorage.setItem("goals", []);
        localStorage.setItem("goals", JSON.stringify(this.swiperData));
        this.$router.push("/performance/goal/summary");
      }
    },
  },
};
</script>